import React, { useState, useEffect, useMemo, useRef } from 'react'
import { t } from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup, Label, Input, Button, CardBody, Card, CardFooter } from 'reactstrap'
import Modal from 'react-modal'
import moment from 'moment'

import { isNull, customStyles, paginationComponentOptions, modalStyles, displayDate } from '../../../izUtils'
import { FilterComponentText, FilterComponentSelect } from './FilterComponent'
import TechnicianDateRange from './TechnicianDateRange'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const TableField = ({data, saveToLS, showErrors, updateData, tokenData, taskData, serviceFrom, serviceTo}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [sameDate, setSameDate] = useState(true);

    const [filterText, setFilterText] = useState("");
    const [filterDepartment, setFilterDepartment] = useState("");
    const [filterType, setFilterType] = useState("technician");

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSelectedtechnician, setModalSelectedTechnician] = useState(null);

    const firstTime = useRef(true);

    useEffect(() => {
        let selected = [];
        const tableTechnicians = data.values?.map(row => {
            if (!isNull(data.value) && data.value.length !== 0) {
                if (Array.isArray(data.value)) {
                    let findTech = data.value.find(tech => tech.id === row.id);
                    if (findTech) {
                        selected.push(findTech)
                    }
                }
            }

            return {...row, name: isNull(row.title) ? row.name : row.title}
        })

        setSelectedRows(selected)
        localStorage.setItem(saveToLS, JSON.stringify(selected))
        setTableData(isNull(tableTechnicians) ? [] : tableTechnicians)
    }, [/*JSON.stringify(data.values)*/]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (firstTime.current) {
            firstTime.current = false;
            return;
        }

        if (sameDate) {
            let clonedSelectedRows = [...selectedRows];
            clonedSelectedRows.map(selected => {
                selected.assigned = [{ from: serviceFrom, to: serviceTo }];
                return selected;
            })

            setSelectedRows(clonedSelectedRows)
            localStorage.setItem(saveToLS, JSON.stringify(clonedSelectedRows))
        }
    }, [sameDate, serviceFrom, serviceTo]) // eslint-disable-line react-hooks/exhaustive-deps

    // Create table columns
    const tableColumns = useMemo(() => {
        let columns = [
            { name: t('table.name'), selector: row => row.name, sortable: true },
            {
                name: t('table.tasksPerDay'),
                selector: row => row.total_assignments,
                sortable: true,
                cell: row => (row.total_assignments > 0) ? <div onClick={() => handleModalButton(row)}><b>{row.total_assignments}</b></div> : row.total_assignments
            },
            {
                name: t('table.department'),
                selector: row => row.department,
                sortable: true,
                cell: row => {
                    return (
                        <div>
                            {row.department.map((dep, index) => (
                                <div key={'dep'+index}>{t(`table.techDepartment.${dep}`)}</div>
                            ))}
                        </div>
                    )
                }
            },
            {
                name: t('table.dates'),
                cell: row => {
                    let isSelected = selectedRows.find(selected => selected.id === row.id);
                    return (
                        <div>
                            {(data.disabled || isSelected) &&
                                isSelected?.assigned.map((element, index) => {
                                    return (
                                        <div key={row.id+'-'+index} className='d-flex flex-wrap mb-2'>
                                            <TechnicianDateRange
                                                index={index}
                                                element={element}
                                                row={row}
                                                disabled={sameDate || data.disabled}
                                                selectedRows={selectedRows}
                                                handleAssigned={handleAssigned}
                                                localStorageName={saveToLS}
                                                serviceFrom={serviceFrom}
                                                serviceTo={serviceTo}
                                            />
                                        </div>
                                    )
                                })
                            }
                            {(!data.disabled && !sameDate) && <button className="btn btn-primary btn-sm py-1 px-2" disabled={!isSelected} onClick={() => addNewAssigned(row)}>{t('addDate')}</button>}
                        </div>
                    )
                }
            },
        ]

        if (!data.disabled) {
            columns.unshift(
                {
                    name: '',
                    width: '50px',
                    cell: row => {
                        let isSelected = selectedRows.find(selected => selected.id === row.id)
                        let disabled = false;

                        if (tokenData.parsedToken.type === 'technician' && taskData.task_status === 'preview' && tokenData.parsedToken.id === row.id) {
                            disabled = true;
                        }

                        return (
                            <div className="custom-control custom-checkbox form-check">
                                <div className="form-checkbox-group">
                                    <Input
                                        className={"custom-control-input"}
                                        id={row.id + '-' + saveToLS}
                                        type="checkbox"
                                        onChange={() => handleRowSelected(row)}
                                        checked={isNull(isSelected) ? false : true}
                                        disabled={disabled}
                                    />
                                    <Label className="checkmark secondary" htmlFor={row.id + '-' + saveToLS}></Label>
                                </div>
                            </div>
                        )
                    }
                },
            )
        }

        return columns;
    }, [selectedRows, data.disabled, sameDate, serviceFrom, serviceTo]) // eslint-disable-line react-hooks/exhaustive-deps

    // Row handlers
    const handleRowSelected = (row) => {
        let clonedSelectedRows = [...selectedRows];
        let isSelected = clonedSelectedRows.find(selected => selected.id === row.id);
        let lsTaskData = JSON.parse(localStorage.getItem('AKODA.taskInputs'));

        if (!isSelected) {
            clonedSelectedRows.push({ id: row.id, assigned: sameDate ?  [{ from: lsTaskData.service_from.value, to: lsTaskData.service_to.value }] : [] })
        } else {
            let filtered = clonedSelectedRows.filter(selected => selected.id !== row.id)
            clonedSelectedRows = filtered;
        }

        setSelectedRows(clonedSelectedRows)
        localStorage.setItem(saveToLS, JSON.stringify(clonedSelectedRows))
        updateData()
    }

    const handleModalButton = (row) => {
        if (!isNull(row) && !isNull(row.events)) {
            setModalSelectedTechnician(row)
            setModalOpen(true)
        } else {
            setModalSelectedTechnician(null)
            setModalOpen(false)
        }
    }

    const addNewAssigned = (row) => {
        let clonedSelectedRows = [...selectedRows];

        clonedSelectedRows.map(selected => {
            if (selected.id === row.id) {
                selected.assigned.push({ from: '', to: '' });
            }

            return selected;
        });

        setSelectedRows(clonedSelectedRows)
        localStorage.setItem(saveToLS, JSON.stringify(clonedSelectedRows))
    }

    const handleAssigned = (type, row, index, val) => {
        let clonedSelectedRows = [...selectedRows];

        if (!isNull(clonedSelectedRows)) {
            if (type === 'start' || type === 'end') {
                clonedSelectedRows.map((selectedTech) => {
                    if (selectedTech.id === row.id) {
                        if (isNull(selectedTech.assigned[index])) {
                            selectedTech.assigned[index] = {}
                        }

                        if (type === 'start') {
                            selectedTech.assigned[index].from = moment.utc(val).format()
                        } else {
                            selectedTech.assigned[index].to = moment.utc(val).format()
                        }
                    }

                    return selectedTech;
                })
            } else if (type === 'delete') {
                clonedSelectedRows.map((selectedTech) => {
                    if (selectedTech.id === row.id) {
                        selectedTech.assigned.splice(index, 1);
                    }

                    return selectedTech;
                })
            }
        }

        setSelectedRows(clonedSelectedRows)
        localStorage.setItem(saveToLS, JSON.stringify(clonedSelectedRows))
    }
    // End row handlers

    const subHeaderComponent = useMemo(() => {
        const handleClear = (type) => {
            if (type === 'department') {
                setFilterDepartment("");
            } else if (type === 'type') {
                setFilterType("");
            } else if (type === 'text') {
                setFilterText("");
            }
        };

        const optionsDepartment = [
            { id: 'it_video', title: t('table.techDepartment.it_video') },
            { id: 'servicer', title: t('table.techDepartment.servicer') },
            { id: 'installer', title: t('table.techDepartment.installer') },
            { id: 'electrician', title: t('table.techDepartment.electrician') },
            { id: 'external_contractor', title: t('table.techDepartment.external_contractor') },
        ]

        const optionsType = [
            { id: 'technician', title: t('table.userType.technician') },
            { id: 'admin', title: t('table.userType.admin') },
        ]

        return (
            <div className="w-100">
                <div className='d-flex flex-wrap justify-content-between align-items-end'>
                    <div className="d-flex flex-wrap align-items-center">
                        <FilterComponentSelect
                            options={optionsType}
                            onFilter={e => setFilterType(e.target.value)}
                            onClear={() => handleClear('type')}
                            filterValue={filterType}
                            type="type"
                        />
                        <FilterComponentSelect
                            options={optionsDepartment}
                            onFilter={e => setFilterDepartment(e.target.value)}
                            onClear={() => handleClear('department')}
                            filterValue={filterDepartment}
                            type="department"
                        />
                        <FilterComponentText
                            onFilter={e => setFilterText(e.target.value)}
                            onClear={() => handleClear('text')}
                            filterValue={filterText}
                        />
                    </div>
                    {!data.disabled &&
                        <div className='custom-control custom-checkbox form-check'>
                            <div className="form-checkbox-group">
                                <Input
                                    className={"custom-control-input"}
                                    id={"checkbox-same-date"}
                                    type="checkbox"
                                    onChange={() => setSameDate(!sameDate)}
                                    checked={sameDate}
                                />
                                <Label className="checkmark" htmlFor={"checkbox-same-date"}></Label>
                                <Label className="custom-control-label" htmlFor={"checkbox-same-date"} style={{ cursor: 'pointer' }}>{t("form.label.sameDates")}</Label>
                                <div className="invalid-feedback">{"Example invalid feedback text"}</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
      }, [filterText, filterDepartment, filterType, sameDate, data.disabled]);

    let filteredItems = useMemo(() => {
        let filteredTableData = tableData.filter(item => {
            return (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
        });

        filteredTableData = filteredTableData.filter(item => {
            return (filterDepartment === "" || item.department.indexOf(filterDepartment) !== -1)
        });

        filteredTableData = filteredTableData.filter(item => {
            return (filterType === "" || item.type === filterType)
        });

        return filteredTableData;
    }, [tableData, filterText, filterDepartment, filterType])

    if (data.display) {
        return (
            <FormGroup className={"table-with-filters"}>
                <Label>{"Tip uporabnika"/*data.title*/ + (data.mandatory ? " *" : "")}</Label>
                {tableData.length !== 0 ?
                    <DataTable
                        columns={tableColumns}
                        data={filteredItems}
                        pagination
                        paginationComponentOptions={paginationComponentOptions(t)}
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={subHeaderComponent}
                    />
                    :
                    <div>{t('table.noTechnicians')}</div>
                }
                {!isNull(showErrors) && showErrors && !isNull(data.errors) && data.errors.length !== 0 &&
                    data.errors.map((error, index) => (
                        <div key={'material-error-'+index} style={{ color: "red", marginBottom: "10px" }}>{error}</div>
                    ))
                }
                <Modal
                    isOpen={modalOpen}
                    onRequestClose={() => handleModalButton()}
                    style={modalStyles}
                    contentLabel="Security System Component Modal"
                >
                    {!isNull(modalSelectedtechnician) &&
                        <Card className="ribbon-wrapper" style={{ marginBottom: '0px' }}>
                            <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary">{modalSelectedtechnician.name}</div>
                                <div>
                                    {modalSelectedtechnician.tasks.length !== 0 &&
                                    <div className='mb-4'>
                                            <b>{t('tasks')}</b>
                                            {modalSelectedtechnician.tasks.map(task => (
                                                <div key={'task'+task.id} className="my-1 pt-1" style={{ borderTop: '1px solid #999' }}>
                                                    {task.client.client + ', ' + task.facility.name}
                                                    <br />
                                                    {displayDate(moment.utc(task.service_from)) + ' - ' + displayDate(moment.utc(task.service_to))}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {modalSelectedtechnician.events.length !== 0 &&
                                        <div className='mb-4'>
                                            <b>{t('events')}</b>
                                            {modalSelectedtechnician.events.map(event => (
                                                <div key={'event'+event.id} className="my-1 pt-1" style={{ borderTop: '1px solid #999' }}>
                                                    {event.client.client + ', ' + event.facility.name}
                                                    <br />
                                                    {displayDate(moment.utc(event.from)) + ' - ' + displayDate(moment.utc(event.to))}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className='text-start'>
                                    <Button className='primary' onClick={() => handleModalButton()}>{t('close')}</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    }
                </Modal>
      </FormGroup>
        )
    } else {
        return null;
    }
}

export default TableField