import React, { useEffect, useState } from 'react'
import {t} from 'i18next'
import GoogleMapReact from 'google-map-react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import axios from 'axios';

import env from '../../../env/src_config';

const AnyReactComponent = ({ text }) => (
    <div><i className="fa fa-map-marker" style={{ fontSize: '22px', color: 'red' }}></i></div>
);

const Maps = ({address, changeLocation}) => {
    const [selectedCoords, setSelectedCoords] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const defaultProps = {
        center: {
            lat: 46.1203255,
            lng: 14.8156076
        },
        zoom: 9
    };

    useEffect(() => {
        console.log("useEffect:", address);
        if (address) {
            setSelectedAddress(address);
            getAddressCoords(address);
        } else {
            setSelectedAddress('');
            setSelectedCoords(null);
        }

    }, [address])

    const modaltoggle = () => setModalOpen(val => !val);

    const getAddressCoords = async (address) => {
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${env.google_maps_api_key}`);
            const results = response.data.results;

            if (results && results.length > 0) {
                setSelectedCoords({ lat: results[0].geometry.location.lat, lng: results[0].geometry.location.lng });
            }
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    }

    const onMapClick = async (data) => {
        setSelectedCoords({ lat: data.lat, lng: data.lng });

        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.lat},${data.lng}&key=${env.google_maps_api_key}`);
            const results = response.data.results;

            if (results && results.length > 0) {
                setSelectedAddress(results[0].formatted_address);
            } else {
                setSelectedAddress('No address found');
            }

        } catch (error) {
            console.error('Error fetching address:', error);
            setSelectedAddress('Failed to retrieve address');
        }
    }

    const selectLocation = () => {
        changeLocation(selectedAddress)
        modaltoggle();
    }

    return (
        <div>
            <Button color="primary" className="btn-sm mt-2" onClick={modaltoggle}>{t('Google maps')}</Button>
            <Modal isOpen={modalOpen} toggle={modaltoggle} centered size="xl">
                <ModalHeader toggle={modaltoggle}>
                    {selectedAddress ? selectedAddress : <span>&nbsp;</span>}
                </ModalHeader>
                <ModalBody>
                    <div style={{ height: '600px', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: env.google_maps_api_key }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            onClick={onMapClick}
                        >
                            {selectedCoords &&
                                <AnyReactComponent
                                    lat={selectedCoords?.lat}
                                    lng={selectedCoords?.lng}
                                    text="My Marker"
                                />
                            }
                        </GoogleMapReact>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className='text-end'>
                        <Button color="primary" className='me-2' onClick={selectLocation}>{t('select')}</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Maps