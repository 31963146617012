import React, { useEffect, useState, useMemo } from 'react'
import moment from 'moment/moment';
import { t } from 'i18next';
import DatePicker from "react-datepicker";
import { Label } from 'reactstrap'

import { isNull } from '../../../izUtils';

const TechnicianDateRange = ({index, element, row, disabled, handleAssigned, localStorageName, serviceFrom, serviceTo}) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        setStartDate(isNull(element.from) || element.from === '' ? '' : moment(moment.utc(element.from).local().format()).toDate());
        setEndDate(isNull(element.to) || element.to === '' ? '' : moment(moment.utc(element.to).local().format()).toDate());
    }, [element])

    const handleDate = (val, type) => {
        if (type === 'start') {
            if (isNull(startDate) || startDate === '') { // Set time to 07:00 if it's empty
                val = new Date(moment(val).set('hour', 7).set('minute', 0))
            }
            setStartDate(val)
        } else {
            setEndDate(val)
        }

        handleAssigned(type, row, index, val);
    }

    const handleDelete = () => {
        handleAssigned('delete', row, index, null);
    }

    const startMinDate = useMemo(() => moment(moment.utc(serviceFrom).local().format()).toDate(), [serviceFrom]);
    const endMinDate = useMemo(() => moment(moment.utc(serviceFrom).local().format()).toDate() > startDate ? moment(moment.utc(serviceFrom).local().format()).toDate() : startDate, [startDate, serviceFrom]);
    const maxDate = useMemo(() => moment(moment.utc(serviceTo).local().format()).toDate(), [serviceTo]);

    const startExcludeDateIntervals = []
    let lsData = JSON.parse(localStorage.getItem(localStorageName));
    lsData.forEach(element => {
        if (element.id === row.id) {
            element.assigned.forEach((assigned, i) => {
                if (i !== index) {
                    if (!isNull(assigned.from) &&  assigned.from.length !== 0 && !isNull(assigned.to) && assigned.to.length !== 0) {
                        startExcludeDateIntervals.push({
                            start: moment(moment.utc(assigned.from).local().format()).startOf('day').toDate(),
                            end: moment(moment.utc(assigned.to).local().format()).endOf('day').toDate(),
                        })
                    }
                }
            });
        }
    });

    return (
        <>
            <div className="d-flex flex-wrap align-items-end pb-2"  style={{ borderBottom: '1px solid #ced4da' }}>
                <div className="mb-1 me-2">
                    <Label>{t('form.label.from')}</Label>
                    <DatePicker
                        className={"datepicker-here form-control"}
                        selected={startDate}
                        onChange={(date) => handleDate(date, 'start')}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startMinDate}
                        maxDate={maxDate}

                        excludeDateIntervals={startExcludeDateIntervals}

                        dateFormat='dd.MM.yyyy HH:mm'
                        calendarStartDay={1}
                        disabled={disabled}
                        showTimeSelect={true}
                        timeFormat='HH:mm'
                        timeIntervals={5}
                    />
                </div>
                <div className="mb-1 me-2">
                    <Label>{t('form.label.to')}</Label>
                    <DatePicker
                        className={"datepicker-here form-control"}
                        selected={endDate}
                        onChange={(date) => handleDate(date, 'end')}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={endMinDate}
                        maxDate={maxDate}

                        excludeDateIntervals={startExcludeDateIntervals}

                        dateFormat='dd.MM.yyyy HH:mm'
                        calendarStartDay={1}
                        disabled={disabled}
                        showTimeSelect={true}
                        timeFormat='HH:mm'
                        timeIntervals={5}
                    />
                </div>
                {!disabled &&
                    <div className="my-2">
                        <button className="btn btn-danger btn-sm py-1 px-2" onClick={(e) => handleDelete()}>
                            <i className="icon-trash"></i>
                        </button>
                    </div>
                }
            </div>
        </>
    );
}

export default TechnicianDateRange