import React from 'react'
import {t} from 'i18next'
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as dates from 'date-arithmetic'
import PropTypes from 'prop-types'
import { Table, Col } from 'reactstrap'
import moment from 'moment/moment';
import axios from 'axios';
import Swal from 'sweetalert2';

import env from '../../../env/src_config';
import { isNull, displayDate } from '../../../izUtils'
import { handleEvent } from '../helper/handleEvent'
import { tokenState, headersState, calendarRefresh } from '../../../recoil/recoil';
import { axiosError, errorStatus } from '../../../helpers/response';
import holidaysArray from "../helper/holidays";

function rangeFunc(start, end, unit = 'day') {
    let current = start
    const days = []
    while (dates.lte(current, end, unit)) {
        days.push(current)
        current = dates.add(current, 1, unit)
    }
    return days
}

const isInRange = (date, task) => {
    return dates.inRange(date, task.start, task.end, 'day')
}

export const AgendaView = ({ accessors, localizer, length, date, events }) => {
    const Navigate = useNavigate()
    const headers = useRecoilValue(headersState);
    const token = useRecoilValue(tokenState);
    const setCalRefresh = useSetRecoilState(calendarRefresh);

    const renderHeader = (day, idx) => {
        const isWeekend = (day.getDay() === 6 || (day.getDay()  === 0 || holidaysArray.indexOf(moment(day).format('yyyy-MM-DD')) !== -1));
        const isToday = (moment(day).isSame(new Date(), 'day'));
        return <th key={idx} scope="col" style={{ backgroundColor: isToday ? "#cdcdcd" :  (isWeekend ? '#eee': '#fff') }}>{localizer.format(day, 'DD.MMMM')}</th>
    }

    function deleteProject(event) {
        axios.delete(env.api + '/api/calendar/event/delete/' + event.id, {headers}).then(response => {
            if (response.data.status === 'success') {
                Swal.fire({
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonColor: 'var(--theme-default)',
                    confirmButtonText: t('ok'),
                })
                setCalRefresh(true);
            } else {
                errorStatus(response.data, t);
            }
        }).catch(error => {
            axiosError(error, Navigate);
        })
    }

    const renderTaskDay = (task, index, deleteProject) => {
        if (task.type === 'task') {
            let color = '#dc3545';
            let backgroundColor = 'transparent';

            if (task.serviceType.id === 'upgrade') {
                color = '#000';
            } else if (task.serviceType.id === 'flat_rate') {
                color = '#51bb25';
            }

            if (["preview"].indexOf(task.status) !== -1) {
                // If task is in the past
                if (dates.lte(task.end, dates.add(moment().toDate(), -1, 'day'), 'day')) {
                    color = '#ffcc00';
                }
            }

            if (["checkup",  "in_approval", "approved"].indexOf(task.status) !== -1) backgroundColor = '#ddd'

            return (
                <div
                    style={{ color: color, backgroundColor: backgroundColor }}
                    key={'task-day-'+task.title+'-'+localizer.format(date, 'DD.MMMM')+'-'+index}
                    className="agenda-cell"
                    onClick={() => handleEvent(task, t, Navigate, token, deleteProject)}
                >
                    <>
                        {(
                            (!isNull(task.facility) ? task.facility.title : '/') +
                            ((!isNull(task.security_systems) && task.security_systems.length !== 0) ?
                                (' (' + task.security_systems.map(ss => ss.title) + ') ')
                                : ' /'
                            )
                        )}
                        {task.important ? t('calendar.important') + ' - ' + displayDate(task.start, 'time') : ""}
                        {!isNull(task.status) ?  ' - ' + t(`status.${task.status}`) : ""}
                    </>
                </div>
            )
        } else if (task.type === 'event') {
            return <div style={{ color: '#000' }} key={'task-day-'+task.title+'-'+localizer.format(date, 'DD.MMMM')+'-'+index} className="agenda-cell" onClick={() => handleEvent(task, t, Navigate, token, deleteProject)}>
                {( (!isNull(task.facility) ? task.facility.title : '/') + (!isNull(task.project_number) ? ', ' + task.project_number : '') )}
            </div>
        } else {
            let exceptionName = (isNull(task.exception) ? '/' : t('calendar.' + task.exception));
            if ((task.exception === "vacation") && (!isNull(task.substitute_technician))) {
                exceptionName += ' (' + t('calendar.substitute') + ': ' + task.substitute_technician.name + ')';
            }

            if (!isNull(task.exception) && task.exception === 'other') exceptionName = task.exception_name;
            return <div style={{ color: '#00aeef' }} key={'task-day-'+task.title+'-'+localizer.format(date, 'DD.MMMM')+'-'+index} className="agenda-cell" onClick={() => handleEvent(task, t, Navigate, token, deleteProject)}>
                {exceptionName}
            </div>
        }
    }

    const end = dates.add(date, 6, 'day')
    const range = rangeFunc(date, end, 'day')

    let techniciansDisplay = [];
    let index = 0;

    for (const tech in events) {
        // Technician name
        let techName = events[tech].name;

         // Technician standbys
         let techStandbys = [];
         if (!isNull(events[tech].standbys) && events[tech].standbys.length !== 0) {
            events[tech].standbys.forEach((standby) => {
                if (events[tech].id === standby.standby_technician_1.id) {
                    techStandbys.push(t('calendar.standby_technician_1'))
                }
                if (events[tech].id === standby.standby_technician_2.id) {
                    techStandbys.push(t('calendar.standby_technician_2'))
                }
                if (events[tech].id === standby.standby_technician_cns.id) {
                    techStandbys.push(t('calendar.standby_technician_cns'))
                }
            })
        }

        // Technician tasks and events
        // eslint-disable-next-line
        let taskRow = range.map(date => {
            const dayOfWeek = date.getDay()
            let isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0);
            if (holidaysArray.indexOf(moment(date).format('yyyy-MM-DD')) !== -1) {
                isWeekend = true;
            }

            let taskDay = [];
            events[tech].calendarEvents.forEach((task, j) => {
                const inRange = isInRange(date, task);
                if (inRange) {
                    if (!isNull(task.assigned) && task.assigned.length !== 0) {
                        task.assigned.forEach((assigned, i) => {
                            const inRangeAssigned = isInRange(date, {start: assigned.from, end: assigned.to});
                            if (inRangeAssigned) {
                                taskDay.push(renderTaskDay(task, j, deleteProject))
                            }
                        })
                    } else {
                        taskDay.push(renderTaskDay(task, j, deleteProject))
                    }
                }
            })

            return (<td key={'task-week-'+localizer.format(date, 'DD.MMMM')+'-'+index} style={{ padding: 0, backgroundColor: isWeekend ? '#eee': '#fff' }}>{taskDay}</td>)
        })

        const techRow = (
            <tr key={"technician-row"+events[tech].id}>
                <th scope="row">{index}</th>
                <td>
                    {techName}<br/>
                    {techStandbys.map((standby, i) => <div key={"calendar-standby-"+standby+'-'+i} className="calendar-standby">{standby}</div>)}
                </td>
                {taskRow}
            </tr>
        )

        techniciansDisplay.push(techRow)
        index ++;
    }

    return (
        <div className="card-block row">
            <Col sm="12" lg="12" xl="12">
                <div className="table-responsive">
                    <Table className="table-responsive-sm agenda-table">
                        <thead className="sticky-second-column">
                            <tr>
                                <th scope="col">{"#"}</th>
                                <th scope="col">{t('calendar.user')}</th>
                                {range.map((day, idx) => renderHeader(day, idx))}
                            </tr>
                        </thead>
                        <tbody className="sticky-second-column">
                            {techniciansDisplay}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </div>
    )
}

AgendaView.title = (start, { localizer }) => {
    const end = dates.add(start, 6, 'day')
    return localizer.format({ start, end }, 'agendaHeaderFormat')
}

AgendaView.navigate = (date, action) => {
    switch (action) {
        case 'PREV':
            return dates.add(date, -7, 'day')
        case 'NEXT':
            return dates.add(date, 7, 'day')
        default:
            return date
    }
}

AgendaView.propTypes = {
    events: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    length: PropTypes.number,
    selected: PropTypes.object,
    accessors: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,
    localizer: PropTypes.object.isRequired,
}