import axios from "axios";
import { t } from "i18next";
import Swal from "sweetalert2";

import env from "../env/src_config";
import { axiosError } from "./response";
import { isNull } from "../izUtils";

export const changeStatus = async (save, status, id, headers, Navigate, form = null, changeSupervisor = false) => {
    const payload = { save };
    if (!isNull(status)) payload["status"] = status;
    if (!isNull(form)) payload.data = form;
    if (changeSupervisor) payload.change_supervisor = true;

    let statusButtons = await axios.patch(env.api + '/api/task/status/' + id, payload, {headers}).then(async response => {
        if (save) {
            // Show swall form for no contract or for contract without supervisors
            if (!isNull(response.data.data) && response.data.data.length !== 0) {
                let title = !isNull(response.data.data.message) ? response.data.data.message : t('task.updateStatus.noContract.title');

                if (status === 'in_approval' && changeSupervisor) {
                    title = t('task.updateStatus.changeSupervisor.otherTitle');
                } else if (status === 'approved' && !changeSupervisor) {
                    title = "";
                }

                let html = `<div class="p-2">`;
                response.data.data?.data.forEach((input, index) => {
                    console.log(input)
                    html += `
                        <div class="mb-3">
                            <label class="form-label">${input.title}</label>
                            <input id="swal_${input.name}" class="swal-form-control form-control ${input.mandatory ? 'is-mandatory' : ''}" name="${input.name}">
                        </div>
                    `
                });
                html += `</div>`

                const { value: formValues } = await Swal.fire({
                    title: title,
                    html: html,
                    showCancelButton: true,
                    cancelButtonText: t('cancel'),
                    focusConfirm: false,
                    preConfirm: () => {
                        let allInputs = document.getElementsByClassName('swal-form-control');
                        let proceed = true;
                        let inputs = {};

                        for (let i = 0; i < allInputs.length; i++) {
                            let input = allInputs[i];
                            inputs[input.name] = input.value;
                            if (input.classList.contains('is-mandatory') && (isNull(input.value) || input.value.length === 0)) {
                                input.classList.add('mandatory');
                                Swal.showValidationMessage(t(`task.updateStatus.noContract.error.${input.name}`));
                                proceed = false;
                            } else {
                                input.classList.remove('mandatory');
                            }
                        }

                        if (proceed) {
                            return inputs;
                        }
                    }
                });

                if (formValues) {
                    changeStatus(true, status, id ,headers, Navigate, formValues, changeSupervisor)
                }
            } else {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        if (status === 'preview') {
                            window.location.reload();
                        } else {
                            Navigate('/')
                        }
                    })
                } else {
                    Swal.fire({
                        title:t('error'),
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    })
                }
            }
        } else {
            return response.data.available_status
        }
    }).catch(error => {
        axiosError(error);
    });

    return statusButtons;
}