import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'
import { Row, Col, Form, Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../../env/src_config'
import { isNull } from '../../../izUtils'
import { linker, transformer } from '../../../helpers/fields'
import { axiosError, errorStatus } from '../../../helpers/response'
import { headersState } from '../../../recoil/recoil'
import Spinner from '../../spinner/Spinner'


const AddFacilityCategory = ({modaltoggle, refreshParent}) => {
    const navigate = useNavigate();

    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        updateData(false, null);

      }, []) // eslint-disable-line

      const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        axios.post(env.api + '/api/facility_category/create', payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    refreshParent(response.data.id);
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        modaltoggle();
                    })
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) {
                let transformedResponse = transformer(response.data.data)
                setInputs(transformedResponse);
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    return (
        <>
            <ModalHeader toggle={modaltoggle}>
                {t('facilities.category.create.title')}
            </ModalHeader>
            <ModalBody>
                {isNull(inputs) ?
                    <Spinner />
                    :
                    <Form className="theme-form">
                        <Row>
                            <Col sm="12">
                                { ['name'].map(field => mapLinker(field)) }
                            </Col>
                        </Row>
                    </Form>
                }
            </ModalBody>
            <ModalFooter>
                <div className='w-100 d-flex justify-content-between'>
                    <Button color="danger" className='me-2' onClick={modaltoggle}>{t('close')}</Button>
                    <Button color="primary" className="me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button>
                </div>
            </ModalFooter>
        </>
    )
}

export default AddFacilityCategory