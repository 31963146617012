import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import { Row, Col } from 'reactstrap'
import Swal from 'sweetalert2';

import env from '../../../env/src_config'
import { isNull } from '../../../izUtils'
import { headersState, updateProjectSystems } from '../../../recoil/recoil'
import { linker, transformer } from '../../../helpers/fields'
import { getSingleItem } from '../../../helpers/getSingleItem'
import { axiosError, errorStatus } from '../../../helpers/response'
import Spinner from '../../spinner/Spinner'
import DisplayFields from '../../../helpers/displayFields'

const ProjectSystems = ({/*index,*/ systemId}) => {
    const navigate = useNavigate();
    const { projectMethod } = useParams();

    const headers = useRecoilValue(headersState);
    const setUpdatePS = useSetRecoilState(updateProjectSystems);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false);

    const lsProjectSystemInputs = 'AKODA.projectSystemInputs-'+systemId;

    useEffect(() => {
        if (!isNull(projectMethod) && projectMethod !== 'get') {
            updateData(false, null);
        } else {
            getSingleItem(headers, systemId, '/api/project/security_system/', navigate).then(data => {
                if (!isNull(data)) {
                    setInputs(data.fields);
                    // localStorage.setItem(lsProjectSystemInputs, JSON.stringify(data.fields));
                }
            })
        }

        return () => {
            localStorage.removeItem(lsProjectSystemInputs)
        }
    }, [systemId]) // eslint-disable-line

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }

            // Get data from localStorage
            let projectSystemInputs = JSON.parse(localStorage.getItem(lsProjectSystemInputs))
            if (!isNull(projectSystemInputs)) {
                if (isNull(payload.data)) payload.data = {};
                payload.data.project_materials = projectSystemInputs.project_materials.value;
            }
        }

        axios.patch(env.api + '/api/project/security_system/' + systemId, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        console.log("Ka pa zdej?")
                    })
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) {
                let transformedResponse = transformer(response.data.data)
                setInputs(transformedResponse);
                localStorage.setItem(lsProjectSystemInputs, JSON.stringify(transformedResponse));
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);

        setUpdatePS(prev => {
            let clonedUpdatePS = [...prev];
            let idx = clonedUpdatePS.findIndex(x => x === systemId);
            if (idx === -1) {
                clonedUpdatePS.push(systemId)
            }
            return clonedUpdatePS;
        })
    }

    const projectMaterialChange = (value, name) => {
        setUpdatePS(prev => {
            let clonedUpdatePS = [...prev];
            let idx = clonedUpdatePS.findIndex(x => x === systemId);
            if (idx === -1) {
                clonedUpdatePS.push(systemId)
            }
            return clonedUpdatePS;
        })
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors: showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            projectMaterialHandler: (value, id) => projectMaterialChange(value, id),
            lsName: lsProjectSystemInputs,
        }

        if (projectMethod === 'get') {
            if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
        } else {
            return linker(inputlinkerFields);
        }
    }

    return (
        <div>
            {isNull(inputs) ?
                <Spinner />
                :
                <>
                    <Col md="12">
                        <Row>
                            { ['security_system', 'estimated_time', 'project_materials', 'project_log_materials'].map(field => mapLinker(field)) }
                        </Row>
                        {/* {system.method === 'create' ?
                            <div className='text-end'>
                                <Button color="danger" onClick={() => removeSystemId(index)}>{t('projects.projectMaterials.delete.system')}</Button>
                            </div>
                            :
                            <br/>
                        } */}
                    </Col>
                </>
            }
        </div>
    )
}

export default ProjectSystems