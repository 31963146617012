import React, {useState, useEffect} from 'react'
import {t} from 'i18next'
import { FormGroup, Label, Button } from 'reactstrap'

import { isNull } from '../../izUtils'
import Maps from './custom/Maps'
import DisplayHistory from '../../helpers/DisplayHistory'
import { checkHistory } from '../../helpers/checkHistory'

const TextInput = ({data, onChange, onBlur, showErrors, lsFilterName, history, component}) => {
    const { type, name, display, errors, title, disabled, mandatory} = data
    const isFilter = !isNull(lsFilterName)

    const [value, setvalue] = useState("")

    useEffect(() => {
        setvalue(data.value === null ? '' : data.value)
    }, [data.value])

    const handleChange = (e) => {
        setvalue(e.target.value)

        // Save filter data to LS, so it can be used if user clicks "Confirm" button
        if (isFilter) {
            let lsFilter = JSON.parse(localStorage.getItem(lsFilterName))
            if (!isNull(lsFilter)) {
                for (const filterName in lsFilter) {
                    if (filterName === name) {
                        lsFilter[filterName].value = e.target.value;
                        localStorage.setItem(lsFilterName, JSON.stringify(lsFilter))
                    }
                }
            }
        }
    };

    // Regular fields call BE on blur
    const handleBlur = () => {
        if (!isFilter && !isNull(onBlur)) onBlur(value, name)
    }

    // Filter fields call BE when enter is pressed
    const handleKeyDown = (e) => {
        if (isFilter && e.keyCode === 13) onChange(value, name)
    }

    let gmapsLocation = null;
    if (!isFilter && (name === 'location' || name === 'google_location')) {
        gmapsLocation = value;
    }

    const checkLocation = () => {
        onBlur(value, name, true, false)
    }

    const checkDistance = () => {
        onBlur(value, name, false, true)
    }

    const changeLocation = (address) => {
        onBlur(address, name)
    }

    if (display) {
        let hasHistory = false;
        if (!isNull(history)) {
            hasHistory = checkHistory(data, history)
        }

        return (
            <FormGroup style={{ position: 'relative' }} className={hasHistory ? 'red-alert' : ''}>
                <Label>{title + (mandatory ? " *" : "")}</Label>
                <input
                    className={"form-control"}
                    disabled={disabled}
                    type={type}
                    name={name}
                    placeholder={t(`form.placeholder.${name}`)}
                    onChange={handleChange}
                    value={value}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
                {!isNull(gmapsLocation) && <a href={'https://www.google.com/maps/place/' + gmapsLocation} target="_blank" rel="noreferrer" className='gmaps-location-marker'><i className="fa fa-map-marker"></i></a>}
                {name === 'work_estimate' && <div className='input-note'>{t(`form.note.${name}`)}</div>}
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors.map((error, index) => <div key={"error-"+index}>{error}</div>)}</div>}
                {hasHistory && <DisplayHistory history={history} />}
                {name === 'google_location' && <Maps address={data.value} changeLocation={changeLocation} />}
                {(!isFilter && component?.id === 'createFacility' && name === 'location') &&
                    <div>
                        <Button color="primary" className="btn-sm mt-2 me-2" onClick={checkLocation}>{t('form.checkLocation')}</Button>
                        <Button color="primary" className="btn-sm mt-2 me-2" onClick={checkDistance}>{t('form.checkDistance')}</Button>
                    </div>
                }
            </FormGroup>
        );
    } else {
        return null;
    }
}

export default TextInput